@font-face {
  font-family: "Graphie-Regular";
  src: url("../public/assets/fonts/graphie/Graphie-Regular.ttf")
    format("truetype");
}

@font-face {
  font-family: "Graphie-Bold";
  src: url("../public/assets/fonts/graphie/fonnts.com-Graphie_Bold.otf")
    format("opentype");
}

@font-face {
  font-family: "Graphie-Book";
  src: url("../public/assets/fonts/graphie/fonnts.com-Graphie_Book.otf")
    format("opentype");
}

@font-face {
  font-family: "Graphie-Light";
  src: url("../public/assets/fonts/graphie/fonnts.com-Graphie_Light.otf")
    format("opentype");
}

@font-face {
  font-family: "Graphie-Thin";
  src: url("../public/assets/fonts/graphie/fonnts.com-Graphie_Thin.otf")
    format("opentype");
}

@font-face {
  font-family: "Graphie-SemiBold";
  src: url("../public/assets/fonts/graphie/fonnts.com-Graphie_SemiBold.otf")
    format("opentype");
}


body,
* {
  font-family: "Hind", sans-serif;
  box-sizing: border-box;
}
.header-icons {
  height: 8rem;
  width: 8rem;
  margin: 0px auto;
}
.btn-gradiant {
  background-image: linear-gradient(
    to right,
    #28dd7a 0%,
    #185a9d 51%,
    #45ce43 100%
  );
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border: none;
  font-weight: 700;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  padding: 10px 12px;
  font-size: 14px;
  min-width: 110px;
}

.btn-newgradiant {
  background-image: linear-gradient(90deg, #434343 0, #0cc3e1 51%, #45ce43);
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border: none;
  font-weight: 700;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  padding: 10px 12px;
  font-size: 14px;
  min-width: 110px;
}
.btn-outline-custom {
  outline: 1px solid #f78d30;
  color: #f78d30;
  cursor: pointer;
  font-weight: 500;
}
.btn-outline-custom:hover {
  background: #f78d30;
  color: white;
}

.header-heading1 {
  font-size: 56px;
}
.btn-gradiant:hover {
  /* background: #016cff; */
  color: #fff;
  text-decoration: none;
}
.header-search-input {
  font-size: 12px !important;
  padding: 10px 15px;
}

.track-card.bg-light-success {
  background: #d5f9e2;
}
.track-card.bg-light-success > .track-profession {
  color: rgb(31, 192, 91);
}

.track-card.bg-light-progress {
  background: #fcefbe;
}
.track-card.bg-light-progress > .track-profession {
  color: rgb(244, 152, 31);
}
.track-card.bg-light-danger {
  background: #f4bab6;
}
.track-card.bg-light-danger > .track-profession {
  color: rgb(225, 64, 20);
}
.track-card.bg-light-default {
  background: #8080803b;
}
.track-card.bg-light-default > .track-profession {
  color: grey;
}
.secondry-heading {
  font-size: 19px;
}
.btn-to-link {
  gap: 6px;
}
.icon-btn_track img {
  display: block;
  height: 100%;
  max-height: 20px;
  max-width: 20px;
  margin: auto;
}
.animation-container {
  position: relative;
  overflow: hidden;
  /* Ensure content does not overflow */
}
.animation-content {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  transform: translateX(100%);
  transform-origin: right;
  opacity: 0;
  transition: transform 1s ease 0.5s, opacity 1s ease 0.5s;
}

.animation-content.show {
  opacity: 1;
  transform: translateX(0);
}
.fs-s {
  font-size: 14px;
}
.head-color {
  color: rgb(3, 96, 132);
}
.bg-warning {
  background: rgb(244, 152, 31) !important;
}
.text-warning {
  color: rgb(244, 152, 31) !important;
}

.text-gov-tundora {
  color: #434343;
}

.form-auth {
  width: 100%;
  /* max-width: 420px; */
  /* padding: 15px; */
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-auth input {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  border-radius: 50px;
  padding: 10px 15px;
  font-size: 14px;
  background: #ececec;
  border: 0px;
}

.form-auth > button {
  display: flex;
  justify-content: center;
  border-radius: 5px;
  border-radius: 10px;
  padding: 14px;
  font-size: 14px;
  border: 0px;
}
.btn-copy:active {
  color: green !important;
}

.dt-length > label {
  margin-left: 15px;
}

.primary-shadow {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.remove-notification:active {
  color: black !important;
}
.navigation-buttons {
  border: none;
  outline: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  background-color: transparent;
}
.navigation-buttons:active {
  outline: none;
  color: #016cff;
}
.ff-hind {
  font-family: "Hind", sans-serif;
}
.ff-ws {
  font-family: "Work Sans", sans-serif;
}
.ff-mont {
  font-family: "Montserrat", sans-serif;
}

.btn-to-link * {
  font-size: 12px;
  font-family: "Montserrat" !important;
}
.fs-17 {
  font-size: 17px;
}

.fs-18 {
  font-size: 18px;
}

.fs-36 {
  font-size: 36px;
}

.fs-16 {
  font-size: 16px;
}

#iframe-signup > iframe {
  height: 100% !important;
  width: 100% !important;
  border: 0px !important;
  box-shadow: none !important;
}
.navigation-buttons > i::before {
  position: relative;
  top: -7px;
}
#bottom-form > p.p-0.m-0 {
  padding-top: 10px;
}
#search-button > i::before {
  position: relative;
  top: -5px;
}
@media (max-width: 767px) {
  .btn.btn-gradiant {
    min-width: 30px;
  }
  main.px-3.pt-5 {
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin: 0px;
  }
  .nav-masthead {
    margin-top: 20px;
  }
  #iframe-signup {
    width: auto;
  }
  nav.nav-masthead > span.text-secondary {
    width: 100% !important;
  }
  .header-bar > .container {
    flex-direction: column;
  }
  .header-bar > .container > nav {
    display: flex;
    justify-content: end !important;
    gap: 10px;
    width: 100%;
    align-items: center !important;
  }
  #log-btn {
    order: 1;
  }
  .nav.nav-masthead > span.text-secondary:first-child {
    width: auto;
    margin-bottom: 0px;
  }
  .header-logo > img {
    height: 60px;
  }
  #iframe-signup > iframe {
    max-width: 100% !important;
  }
  .header-heading1 {
    font-size: 35px;
  }
  p.secondry-heading {
    font-size: 13px !important;
  }
  .animation-content {
    position: static !important;
  }
  #bottom-form {
    flex-wrap: wrap;
    align-items: center;
  }
  #bottom-form .dropdown {
    width: 100%;
  }
  .cover-container {
    padding: 0px !important;
  }
  .onboarding-text-center {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
  }
  .onboarding-rounded-circle {
    width: 100px;
    height: 100px;
  }

  .onboarding-flexcolumn {
    flex-direction: column;
  }

  .onboarding-width {
    width: 100%;
  }

  .onboarding-paddingtop {
    padding-top: 0px !important;
  }

  .onboardig-marginbottom {
    margin-bottom: 5px;
  }

  .onboarding-fs-24 {
    font-size: 24px !important;
  }

  .onboarding-fs-20 {
    font-size: 20px !important;
  }

  .onboarding-fs-14 {
    font-size: 14px !important;
  }

  .onboardin-padding-24 {
    padding-left: 24px !important;
  }

  .onboarding-text-align-left {
    text-align: left;
  }

  .onboarding-display-none::before {
    display: none;
  }

  .onboarding-padding-left-0 {
    padding-left: 0px !important;
  }

  .onboarding-display-block {
    display: block;
  }

  .onboarding-margin-top-16 {
    margin-top: 16px !important;
  }

  .onboarding-font-size-30 {
    font-size: 30px;
  }

  .onboarding-margin-top-18 {
    margin-top: 18px !important;
  }

  .onboarding-min-height-120 {
    /* min-height: 120px !important; */
  }

  .onboarding-text-align-end {
    text-align: end;
  }
}

@media (min-width: 500px) and (max-width: 769px) {
  .onboarding-text-center {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
  }

  .onboarding-button {
    display: flex;
    justify-content: space-evenly;
  }

  .onboarding-rounded-circle {
    width: 100px;
    height: 100px;
  }

  .onboarding-flexcolumn {
    flex-direction: column;
  }

  .onboarding-width {
    width: 100%;
  }

  .onboarding-paddingtop {
    padding-top: 0px;
  }

  .onboardig-marginbottom {
    margin-bottom: 5px;
  }

  .onboarding-fs-20 {
    font-size: 20px !important;
  }

  .onboarding-fs-14 {
    font-size: 14px !important;
  }

  .onboarding-fs-24 {
    font-size: 24px !important;
  }

  .onboardin-padding-24 {
    padding-left: 24px;
  }

  .onboarding-text-align-left {
    text-align: left;
  }
}

@media (min-width: 500px) and (max-width: 999px) {
  .onboarding-rounded-circle {
    width: 100px;
    height: 100px;
  }
}


.governify-header-major-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 80%;
}


.governify-header-major-div-buttons {
  /* width: 20%; */
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 14px;
}

.governify-primary-btn {
  background-color: #5ac063;
  color: #eee;
  font-weight: 500;
  height: 40px;
}

.region-select:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  box-shadow: 0 0 0 0 !important;
}

.region-select .ant-select-selector {
  background: #F7F7F7 !important;
  border-radius: 50px;
  border: none !important;
  box-shadow: none !important;
  padding: 20px 15px !important;
}

.region-select .ant-select-selection-search input {
  padding-left: 2px !important;
}

.region-select .ant-select-selection-placeholder {
  color: #454545;
}

.region-select .ant-select-arrow {
  padding: 10px 3px 0 0;
  color: #454545;
}

.text-align-center {
  text-align: center;
}

.h-40 {
  height: 40px;
}

.p-10 {
  padding: 10px;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs_14 {
  font-size: 14px;
}

.fs-15 {
  font-size: 15px;
}

.fs_16 {
  font-size: 16px;
}

.fs-17 {
  font-size: 17px;
}

.fs_18 {
  font-size: 18px;
}

.fs-19 {
  font-size: 19px;
}

.fs_20 {
  font-size: 20px;
}

.fs-21 {
  font-size: 21px;
}

.fs-24 {
  font-size: 24px;
}

.fs-26 {
  font-size: 26px;
}

.fs-28 {
  font-size: 28px;
}

.mr-10 {
  margin-right: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-8 {
  margin-top: 8px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-16 {
  margin-bottom: 16px;
}

.border-radius-10 {
  border-radius: 10px;
}

.text-color-928f8f {
  color: #928f8f;
}

.text-white {
  color: #fff;
}

.text-color-434343 {
  color: #434343;
}

.text-color-29cf10 {
  color: #29cf10;
}

.text-color-5AC063 {
  color: #5ac063;
}

.text-color-0d6efd {
  color: #0d6efd;
}

.text-color-497ed8 {
  color: #497ed8;
}

.text-decoration-underline {
  text-decoration: underline;
}

.governify-cursor-pointer {
  cursor: pointer;
}

.governify-sortby-button {
  padding-left: 0px;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.font-family-montse {
  font-family: "Montserrat", sans-serif;
}

.font-family-hind {
  font-family: "Hind", sans-serif;
}

.governify-secondary-btn {
  color: #5ac063;
  border: 1px solid #5ac063;
  height: 40px;
  background: transparent;
}

.governify-secondary-btn:hover {
  /* color: #928f8f !important;
  border-color: #928f8f !important;
  background: transparent; */
}

.governify-tab-headers > span > span > svg {
  color: #5ac063;
  font-size: 20px;
  font-weight: 700;
}

.ant-tabs-tab-active > div > div > span:nth-of-type(2) {
  color: #434343;
  font-weight: 300;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin-top: 0px;
}

.governify-header-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 14px;
}

.mt-50 {
  margin-top: 50px;
}

.ant-tabs-tab {
  color: #949494 !important;
}

.ant-tabs-tab-active {
  background-color: #ececec !important;
}

.ant-tabs-tab-active > .ant-tabs-tab-btn {
  color: #44638d !important;
}

.ant-tabs-content-holder {
  text-align: left !important;
}

.service-child-title {
  font-size: 21px !important;
  color: #434343 !important;
  font-weight: 700 !important;
  margin-top: 10px;
}

.service-title {
  font-size: 15px !important;
  color: #434343 !important;
  font-weight: 700 !important;
  margin-top: 10px !important;
}

.service-image {
  display: block;
  width: 100%;
  height: 150px;
}

.service-parent-div {
  display: flex;
  flex-wrap: wrap;
  column-gap: 39px;
  row-gap: 16px;
}

.inc-auth-container {
  background-image: url("/public/authbg.svg");
  background-size: 100%;
}

.service-repetitive-div {
  flex: 1 1 calc(33.333% - 10px);
  box-sizing: border-box;
  margin-top: 0px;
  margin-bottom: 60px;
  position: relative;
  padding-bottom: 60px;
  max-width: calc(33.33% - 26px);
}

.service-image-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.service-subtitle {
  font-size: 15px !important;
  color: #928f8f !important;
  font-weight: 400 !important;
  margin-top: 10px !important;
}

.service-child-subtitle {
  font-size: 15px !important;
  color: #928f8f !important;
  font-weight: 400 !important;
  margin-top: 2px !important;
}

.tabcontent-image {
  margin-top: 50px;
  margin-bottom: 50px;
}

.tabcontent-create-request-btn {
  background-color: #5ac063;
  color: white;
  margin-top: 30px;
  border: 1px solid transparent;
  height: 41px;
}

.tabcontent-create-request-btn {
  font-size: 12px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif !important;
}

/* .tabcontent-create-request-btn:hover {
  color: #5ac063 !important;
  border: 1px solid #5ac063 !important;
}

.tabcontent-create-request-btn:hover {
  color: #5ac063;
  background-color: transparent !important;
  border: 1px solid #5ac063 !important;
} */

.onboardify-welcome-text-hani {
  color: #928f8f !important;
  font-weight: bold !important;
  font-size: 17px !important;
}

.onboardify-welcome {
  font-size: 17px !important;
  color: #928f8f !important;
}


.w-40 {
  width: 40%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.breadcrumb-major-component {
  display: flex;
  text-align: left;
}

.bi-arrow-right-circle-fill::before {
  vertical-align: middle !important;
}

.encrypted i::before {
  vertical-align: middle;
}

.bi-trash3-fill::before {
  vertical-align: middle !important;
  color: #497ed8;
}

.bi-sort-down::before {
  color: #497ed8;
  vertical-align: middle !important;
}

.bi-funnel::before {
  color: #497ed8;
  vertical-align: middle !important;
}

.ant-typography > span > svg {
  color: #497ed8;
  font-size: 20px;
}

.governify-category-icon-parent i::before {
  vertical-align: middle !important;
}

.governify-category-icon-parent-new i::before{
  vertical-align: text-top !important;
}  


.governify-search-box {
  padding: 10px 15px;
  width: 100%;
  font-size: 10px;
  font-weight: 400;
  color: #000 !important;
}

.governify-breadcumb-home {
  font-size: 16px;
  color: #6c757d;
}

.pl-8 {
  padding-left: 8px;
}

.pt-84 {
  padding-top: 84px;
}

.pt-100 {
  padding-top: 100px;
}

.governify-option-list {
  display: flex;
  justify-content: space-around;
  gap: 10px;
}

.governify-option-list-icon {
  font-size: 140px;
}

.governify-option-list-repetitive {
  flex: 1;
  margin: 0 10px;

  padding: 20px;
}

.customer-form-container {
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.form-header {
  font-family: "Hind", sans-serif;
  color: #6f7490;
  font-size: 36px;
  padding: 10px;
  text-align: center;
  font-weight: 700;
  line-height: 1.2;
}

.form-control-font {
  font-size: 14px;
  padding: 4px 5px;
  border-radius: 6px;
}

.form-header-description {
  font-family: "Hind", sans-serif;
  color: #928f8f;
  font-size: 19px;
  padding: 10px;
  text-align: center;
  font-weight: 400;
}

.form-body {
  padding: 15px;
}

.form-item {
  margin-bottom: 15px;
}

.form-label {
  margin-bottom: 5px;
  display: block;
  font-weight: bold;
}

.user-textarea {
  width: 100%;
  /* height: 100px; */
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 10px;
  resize: vertical;
  background-color: #f3f4f8cc;
}

.user-textarea :focus {
  outline: none;
  border-color: #3b99fc;
  border-style: solid;
}

.otpInput {
  border: 1px solid #c9cccf;
  border-radius: 8px;
}

.otpInput::-webkit-inner-spin-button,
.otpInput::-webkit-outer-spin-button,
.phone-input::-webkit-inner-spin-button,
.phone-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.custom-select:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.custom-select .ant-select-selector {
  background: rgb(232, 240, 254) !important;
  border-radius: 50px;
  border: none !important;
  padding: 20px 15px !important;
}

.custom-select-focus .ant-select-selector {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25) !important;
}

.custom-select .ant-select-selection-search input {
  padding-left: 2px !important;
}

.custom-select .ant-select-selection-placeholder {
  color: #454545;
}

.custom-select .ant-select-arrow {
  padding: 10px 3px 0 0;
  color: #454545;
}

.countryOption {
  display: flex;
  gap: 10px;
  align-items: center;
}

.countryOption svg {
  width: 24px !important;
  height: auto;
}

.inc-reply-container img {
  width: 100%;
  height: auto; /* maintain aspect ratio */
}

.incorpify-text-editor .ql-container {
  min-height: 80px;
}

.incorpify-text-editor .ql-toolbar {
  border-radius: 8px 8px 0 0;
}
.incorpify-text-editor .ql-container {
  border-radius: 0 0 8px 8px;
}

#loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* display: none;  */
}

.spinner {
  /* border: 16px solid #f3f3f3;
	border-top: 16px solid #3498db;
	border-radius: 50%;
	width: 120px;
	height: 120px; */
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.hidden-file-input {
  display: none;
}

.custom-button {
  display: inline-block;
  padding: 10px 20px;
  color: black;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

/* Custom CSS */
.form-check-label {
  font-weight: bold;
  color: #555;
}

.form-check-input:checked ~ .form-check-label {
  color: #007bff;
}

.ant-upload-drag {
  background: transparent !important;
  border: none !important;
}

.auth-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-container {
  padding: 30px;
  /* border-radius: 8px; */
  box-shadow: 0 2px 6px #0003;
  max-width: 440px;
  background: #ffffff;
}



@media (max-width: 1000px) {


  .service-parent-div {
    display: flex;
    flex-direction: column;
    align-items: start;
    flex-wrap: wrap;
  }

  .service-repetitive-div {
    flex: 1 1 calc(33.333% - 10px);
    box-sizing: border-box;
    max-width: 90%;
    margin-top: 0px;
    margin-bottom: 60px;
  }
}

@media (max-width: 1000px) {
  .governify-header-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .governify-header-major-div-logo {
    /* width: 30%; */
  }

  .governify-header-major-div-buttons {
    /* width: 70%; */
  }

  .governify-header-major-div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

@media (max-width: 777px) {
  .governify-option-list {
    display: flex;
    justify-content: space-around;
    gap: 40px;
    flex-direction: column;
  }

  /* .governify-header-major-div {
    display: flex;
    flex-direction: column;
    align-items: center;
  } */

  /* .governify-header-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .governify-header-major-div-logo {
    justify-content: center;
    display: flex;
    /* width: 100%; */
  /* } */

  /* .governify-header-major-div-buttons {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
  } */
}

@media (max-width: 540px) {
  .emoji-wrapper {
    display: none;
  }

  .form-container {
    padding: 20px;
  }
}

.bottom-blur {
  backdrop-filter: blur(10px);
  height: 50px;
  position: absolute;
  bottom: 6px;
}

#iframe-chart > iframe {
  border: 0;
  border-radius: 0.5rem;
  box-shadow: 5px 5px 56px 0px rgba(0, 0, 0, 0.25);
  width: 100% !important;
  height: 100% !important;
  transform: scale(1) !important;
}

.ant-card-head-title {
  color: white;
}

.rotating-img {
  animation: rotate 2s linear infinite;
}

.full-loader {
  z-index: 200000;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.281);
  backdrop-filter: blur(1px);
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  /* border: 2px solid rgb(0, 94, 255); */
}

.inc-verify-user {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.inc-verify-user div {
  font-size: 24px;
  color: #20681f;
  background-color: #c4f3c4;
  padding: 24px;
  border-radius: 10px;
  outline: 1px solid #20681f;
}

.option-container {
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.option-heading {
  font-size: 1.2em;
  margin-bottom: 10px;
  color: #333;
}

.option-content {
  display: flex;
  flex-direction: column;
}

.option-label {
  margin-bottom: 10px;
  font-weight: bold;
  color: #555;
}

.option-input,
.option-textarea {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.option-input:focus,
.option-textarea:focus {
  border-color: #007bff;
  outline: none;
}

.option-textarea {
  height: 100px;
}

/* .carousel-container {
  max-width: 100%;
  margin: auto;
} */

/* .carousel-container img {
  width: 100%;
  height: auto;
} */

/* 
.slick-track > div > div {
  height: 100%;
}

.slick-track > div > div > .carousel-slide-wrapper {
  height: 100%;
}

.slick-track > div > div > .carousel-slide-wrapper > div {
  height: 100%;
  display: flex !important;
  flex-direction: column;
}

.slick-next:before {
  content: '' !important;
}

.slick-prev:before {
  content: '' !important;
} */

.carousel {
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.img-container {
  width: 100%;
  height: auto;
  max-width: 263px;
  max-height: 170px;
  border-radius: 10px;
  overflow: hidden;
}

.img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.carousel-wrapper {
  overflow: hidden;
  width: 100%;
}

.carousel-content {
  display: flex;
  transition: transform 0.5s ease-in-out;
  will-change: transform;
}

.carousel-slide-wrapper {
  flex: 1 0 25%;
  max-width: 25%;
  box-sizing: border-box;
  padding: 10px;
}

.carousel-slide-wrapper > div {
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 20px;
  position: relative;
  padding-bottom: 70px;
  height: 100%;
}

.carousel-slide-wrapper img {
  max-width: 100%;
  max-height: 170px;
  border-radius: 10px;
  object-fit: cover;
}

.carousel button {
  background-color: transparent;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  padding: 0 20px;
}

.carousel button:focus {
  outline: none;
}

.craousel-slider-wrapper-child {
  padding: 20px;
  position: relative;
  padding-bottom: 80px;
}

/* Media queries for responsiveness */
@media screen and (max-width: 1094px) {
  .carousel-slide-wrapper {
    flex: 1 0 33.33%; /* Shows 3 items at a time */
    max-width: 33.33%;
  }
}

@media screen and (max-width: 972px) {
  .carousel-slide-wrapper {
    flex: 1 0 50%; /* Shows 2 items at a time */
    max-width: 50%;
  }

  .carousel-content {
    display: flex;
    transition: transform 0.5s ease-in-out;
    will-change: transform;
    width: 700px;
  }
}

@media screen and (max-width: 772px) {
  /* .carousel-slide-wrapper {
    flex: 1 0 100%; /* Consistent with 2 items at a time */
  /* max-width: 100%; */
  /* } */

  .carousel-slide-wrapper {
    flex: 1 0 100%;
    border: 1px solid #e8e8e8;
    border-radius: 12px;
    max-width: 100%;
    box-shadow: 0 2px 14px rgba(0, 0, 0, 0.15);
    max-width: 350px;
    margin-right: 20px;
    padding: 0;
  }
  .carousel-content {
    display: flex;
    transition: transform 0.5s ease-in-out;
    will-change: transform;
    width: 500px;
  }

  .craousel-slider-wrapper-child {
    padding: 20px 20px 60px;
  }

  .img-container {
    width: 100%;
    height: auto;
    /* max-width: 263px; */
    max-height: 170px;
    border-radius: 10px;
    overflow: hidden;
  }

  .img-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
}

/* Container Style */
.new-carousel-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  overflow: hidden;
}

/* Grid Style */
.new-grid {
  display: flex;
  justify-content: center;

  /* grid-template-columns: repeat(4, 1fr); Ensure four columns for larger screens */
  gap: 20px;
}

@media (max-width: 1200px) {
  .new-grid {
    grid-template-columns: repeat(
      auto-fill,
      minmax(270px, 1fr)
    ); /* Responsive grid */
  }
}

/* Card Style */
.new-card {
  display: flex;
  max-width: 270px;
  flex-direction: column;
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  padding-bottom: 70px;
}

.new-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Image Container Style */
.new-img-container {
  width: 100%;
  max-width:270px;
  overflow: hidden;
}

.new-img-container img {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
}

/* Content Style */
.new-content {
  padding: 15px;
  text-align: center;
}

.new-title {
  text-align: left;
  width: 100%;
  color: rgb(67, 67, 67);
  font-size: 24px;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 10px;
}

.new-description {
  text-align: left;
  width: 100%;
  color: rgb(146, 143, 143);
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 20px;
}

/* Button Container Style */
.new-button-container {
  padding: 10px;
  padding-left:0px;
  position: absolute;
  bottom: 10px;
  left: 15px;
}

/* Button Style */
.new-button-container button {
  background-color: transparent;
  color: #000; /* Replace with your desired color */
  border: 1px solid #000; /* Replace with your desired color */
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 14px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.new-button-container button:hover {
  background-color: #000; /* Replace with your desired hover color */
  color: #fff;
}

.onboardify-information-container {
  position: sticky;
  top: -300;
  z-index: 1;
}

.onboardify-information-icon-container {
  display: flex;
  padding-left: 24px;
  margin-bottom: 8px;
  gap: 16px;
}

.onboardify-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.onboardify-trackdetails-padding {
  padding: 2rem;
}

.onboardify-filter-export {
  display: flex;
  justify-content: space-between;
}

.onboardify-service-sort {
  display: flex;
  justify-content: space-between;
}

.onboardify-filter-sort-container {
  display: flex;
  justify-content: left;
  padding-top: 8px;
  margin-bottom: 32px;
}

.onboardify-comment-information-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  height: 100%;
  padding-top: 20px;
}

.onboardify-chat-container {
  gap: 30px;
  overflow-y: visible;
}

.onboardify-icon-label-text-container {
  display: flex;
  gap: 5px;
}

.onboardify-icon-label-container {
  display: flex;
  gap: 5px;
  align-items: center;
}



.onboardify-icon-container-track {
  display: flex;
  align-items: center; /* Aligns children vertically in the center */
}

.onboardify-icon-container-track i::before {
  display: inline-block; /* Ensures the icon respects the flex container */
  vertical-align: middle; /* Aligns the icon vertically in the middle */
}

.onboardify-requestpage-padding{
  padding:1rem;
}

@media (max-width: 994px) {
  .new-carousel-container {
    max-width: 500px;
  }

  .new-grid {
    flex-direction: column;
  }

  .new-card {
    display: flex;
    max-width: 500px;
    flex-direction: column;
    border-radius: 8px;
    background-color: #fff;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    padding-bottom: 70px;
  }

  .onboardify-comment-information-container {
    flex-direction: column;
  }

  .onboardify-information-container {
    position: relative;
    top: 0;
    z-index: 1;
  }

  .new-button-container {
    padding-left: 0px;
  }

  .onboardify-comment-information-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    height: 100%;
    padding-top: 20px;
  }

  .onboardify-chat-container {
    gap: 30px;
    width: 100%;
    overflow-y: visible;
  }

  .new-img-container{
    max-width: 460px;
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .new-title {
    font-size: 20px;
  }

  .new-description {
    font-size: 14px;
  }

  .new-button-container button {
    font-size: 12px;
    padding: 8px 16px;
  }
}


.onboardify-footer-wrapper {
  display: flex;
  gap: 20px;
  justify-content: end;
  align-items: center;
  flex-wrap: wrap;
}

@media (max-width: 480px) {
  .onboardify-filter-sort-container {
    flex-direction: column;
  }

  .onboardify-footer-wrapper {
    display: flex;
    gap: 20px;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
    margin-top:10px;
  }

  .new-title {
    font-size: 18px;
  }

  .new-description {
    font-size: 12px;
  }

  .new-button-container button {
    font-size: 10px;
    padding: 6px 12px;
  }

  .onboardify-information-icon-container {
    flex-direction: column;
  }

  .onboardify-trackdetails-padding {
    padding: 0.5rem;
  }

  .inc-comment-header {
    flex-direction: column;
  }

  .onboardify-requestpage-padding{
    padding:0px;
  }

  /* .onboardify-icon-label-text-container {
   flex-direction: column;
  } */
}

@media (max-width: 1176px) {
  .governify-header-major-div {
    width: 76%;
  }
}

@media (max-width: 1000px) {
  .governify-header-major-div {
    width: 65%;
  }
}

@media (max-width: 778px) {
  .governify-header-major-div {
    width: 100%;
  }

  .onboardify-header-container {
    /* flex-direction: column; */
    gap: 10px;
  }

  .governify-header-major-div-buttons {
    width: 100%;
    justify-content: center;
  }
  .new-button-container {
    padding-left: 0px;
  }
}


.responsive-image-container {
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.responsive-image-container img {
  width: 100%;
  height: auto;
  max-width: 100%;
  display: block;
}
